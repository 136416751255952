import React, {useEffect} from 'react';
import {FiGitCommit, FiInfo, FiLayers} from "react-icons/fi";

const LeftMenu = ({ type, setMenuSelect, menuSelect, activeLeftMenu, handler }) => {

    useEffect(() => {
        const newMenuSelect = leftMenu?.find(item => item.id === activeLeftMenu);
        setMenuSelect(newMenuSelect);

    }, []);


    const leftMenu = [
        {
            id: 1,
            icon: <FiInfo />,
            title: type === "installation" ? "Installation's informations" :  "Service's informations",
            initialTitle: "IOI"
        },
        {
            id: 2,
            icon: <FiLayers />,
            title: "Specific informations",
            initialTitle: "SPI"
        },
        {
            id: 3,
            icon: <FiGitCommit />,
            title: "List of services",
            initialTitle: "LOS"
        }
    ];

    const filteredMenu = type === "services" ? leftMenu.filter(item => item.id !== 3) : leftMenu;

    return (
        <ul className="flex items-center bg-slate-50 rounded-sm z-10 lg:h-14 absolute top-0 left-0 w-full">
            {filteredMenu.map(item =>
                <li
                    key={item.id}
                    className={`lg:h-14 flex flex-col gap-1 items-center rounded-sm ${type === "installation" ? "w-1/3" : "w-1/2" } ${(type !== "installation" && item.id === 3 ) ? "hidden" : "block"} ${activeLeftMenu === item.id ? "font-bold bg-white border border-slate-200 border-b-0 border-t-4" : "bg-transparent text-slate-500 border-b"} ${type === "installation" ? "text-orange-500 border-t-orange-500" : "text-blue-500 border-t-blue-500"}`}
                    title={item.title}
                >
                    <button
                        className={`p-4 lg:h-14 w-full py-2 text-center cursor-pointer transition ease-linear xl:text-sm text-xs`}
                        onClick={() => handler(item.id)}
                    >
                        {item.title}
                    </button>
                </li>
            )}
        </ul>
    );
};

export default LeftMenu;