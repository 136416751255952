import { useEffect, useState } from 'react';
import {FiArrowRight, FiChevronLeft} from "react-icons/fi";

const Pagination = ({ dataLength, setContactActive }) => {
    const [start, setStart] = useState(0);

    useEffect(() => {
        setContactActive(start);
    }, [start, setContactActive]);

    const handleIncrement = () => {
        setStart((prevStart) => (prevStart + 1) % dataLength);
    };

    const handleDecrement = () => {
        setStart((prevStart) => (prevStart - 1 + dataLength) % dataLength);
    };

    return (
        <div className="flex items-center gap-4 text-slate-500">
            <div className="flex items-center">
                <button
                    onClick={handleDecrement}
                    className={`border border-slate-200 rounded-full text-slate-500 text-sm h-6 w-6 flex items-center justify-center`}>
                    <FiChevronLeft/>
                </button>
                <p className="text-slate-500 text-sm px-4 rounded-sm ">{start + 1} / <span>{dataLength}</span></p>
                <button
                    onClick={handleIncrement}
                    className=" border border-slate-200 rounded-full text-slate-500 text-sm h-6 w-6 flex items-center justify-center">
                    <FiArrowRight/>
                </button>
            </div>
        </div>
    );
};

export default Pagination;
