import {Typography} from "@mui/material";
import {truncateTextLetter} from "../../../utils/truncateText";
import { FiMapPin } from "react-icons/fi";
import {Link} from "react-router-dom";
import {useState} from "react";
import LazyImage from "../../../components/LazyImage";
export const Item = ({ item, catalogType, list }) => {

    const storedData = localStorage.getItem("selectedData");
    const parsedData = storedData ? JSON.parse(storedData) : null;

    const [hoveredItem, setHoveredItem] = useState(null);

    return (
        <div className="flex flex-col">
            <div className="flex flex-col gap-2">
                <Typography
                    component="h3"
                    variant="h3"
                    sx={{fontSize: "1.1rem", position: "relative"}}

                    onMouseEnter={() => {
                        const truncatedText = item?.name?.slice(0, 35);

                        if ( truncatedText.length === 35 ) {
                            setHoveredItem(item.name)
                        }

                    }}
                    onMouseLeave={() => setHoveredItem(null)}
                >
                    {truncateTextLetter(item?.name, 35)}

                    { hoveredItem === item.name && (
                        <span className="absolute min-w-[250px] bg-slate-100 border-2 border-slate-500 backdrop-blur  top-full left-1/2 transform -translate-x-1/2 text-slate-500 rounded-md p-2 z-50 transition">
                        {item.name}
                    </span>
                    )}
                </Typography>
                {item?.ri &&
                    <ul className="flex gap-2 items-center flex-wrap">
                        <span className="text-xs text-slate-500 border border-solid border-slate-200 bg-slate-50 p-2 rounded-full">{item?.ri}</span>
                    </ul>
                }

                <ul className="flex flex-wrap gap-2 items-center">
                    {item?.location?.city &&
                        <li className="text-slate-600 flex items-center gap-2" title={item.location.city}><span className="text-sm text-slate-400"><FiMapPin/> </span>{ truncateTextLetter(item.location.city, 15) }</li>
                    }
                    {item?.location?.country &&
                        <li className="text-slate-600 flex items-center gap-2" title={item.location.country}><span className="text-sm text-slate-400"><FiMapPin/> </span>{  truncateTextLetter(item.location.country, 15)}</li>
                    }
                </ul>
            </div>
            <div className={`{${list ? 'lg:h-80' : 'lg:h-52'} h-[280px] w-full relative overflow-hidden order-first rounded-lg mb-4 border border-slate-200`}>
                <LazyImage
                    src={ item.mainImage ? `${process.env.REACT_APP_API_URL}${item.mainImage}` : `/uploads/update.jpg`}
                    alt={` Image of ${item.name}`}
                    list={list}
                />
                <Link to={`/catalog/${parsedData?.networkId}/${catalogType}/${item.id}`} target="_blank" className="absolute top-0 left-0 w-full h-full"/>
            </div>
        </div>
    )
}