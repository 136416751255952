export const Tables = ({ tab }) => {
    const allKeys = tab.flatMap(item => Object.keys(item));
    const uniqueKeys = [...new Set(allKeys)];

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-gray-200">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-slate-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    {uniqueKeys.map((key, index) => (
                        <th key={index} className={`px-6 py-3 ${ index === 0 ? "min-w-52" : "min-w-96"} `}>
                            {key}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {tab.map((item, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                    >
                        {uniqueKeys.map((key, colIndex) => {
                            const cellValue =
                                item[key] !== undefined && item[key] !== "" ? item[key] : "N/A";
                            return (
                                <td
                                    key={colIndex}
                                    className="px-6 py-4 whitespace-normal break-words w-auto"
                                    title={cellValue}
                                >
                                    {cellValue}
                                </td>
                            );
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    );
};