import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Item } from "../../features/catalog/components/Item";
import { Icon } from 'leaflet';
import markerBlue from '../../assets/images/location-blue.png';
import markerOrange from '../../assets/images/location-orange.png';

// Création des icônes
const icons = {
    services: new Icon({
        iconUrl: markerBlue,
        iconSize: [32, 32],
    }),
    installations: new Icon({
        iconUrl: markerOrange,
        iconSize: [32, 32],
    }),
    default: new Icon({
        iconUrl: markerBlue,
        iconSize: [32, 32],
    })
};

const MapLeaflet = ({ data, catalogType, list, coordonates }) => {
    const minZoomLevel = 3;
    const maxZoomLevel = 10;

    const dataCoordinates = data?.filter(item => item?.location?.longitude && item?.location?.latitude);

    // Sélection de l'icône selon le catalogType
    const iconToUse = icons[catalogType] || icons.default;

    return (
        <MapContainer
            center={[46.6035, 1.8888]}
            zoom={2}
            maxZoom={maxZoomLevel}
            minZoom={minZoomLevel}
            trackResize={true}
            style={{
                height: "100%",
                width: '100%',
                zIndex:2,
            }}
            maxBounds={[
                [-90, -180], // Sud-ouest
                [90, 180], // Nord-est
            ]}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            { coordonates ?
                <MarkerClusterGroup>
                    { coordonates?.map((item, index) => (
                        <div key={index}>
                            {(Number(item?.location?.latitude) && Number(item?.location?.longitude)) ? (
                                <Marker position={[parseFloat(item?.location?.latitude), parseFloat(item?.location?.longitude)]} icon={iconToUse}>
                                    <Popup>
                                        <span className="text-slate-700">{item?.location?.street}, {item?.location?.city}, {item?.location?.country}</span>
                                    </Popup>
                                </Marker>
                            ) : ""}
                        </div>
                    ))}
                </MarkerClusterGroup>
                :
                <MarkerClusterGroup>
                    { dataCoordinates?.map((item, index) => (
                        <div key={index}>
                            {(Number(item?.location?.latitude) && Number(item?.location?.longitude)) ? (
                                <Marker position={[parseFloat(item?.location?.latitude), parseFloat(item?.location?.longitude)]} icon={iconToUse}>
                                    <Popup>
                                        <Item item={item} catalogType={catalogType} list={list}/>
                                    </Popup>
                                </Marker>
                            ) : ""}
                        </div>
                    ))}
                </MarkerClusterGroup>
            }
        </MapContainer>
    );
};

export default MapLeaflet;
