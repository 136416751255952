import {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import {FiArchive, FiBell} from "react-icons/fi";
import {dataNotifMenuAuth} from "../../../../utils/data";
import {useCloseOnOutsideClick} from "../../../../hooks/useCloseOnOutsideClick";
import {useFetchActivities} from "../../../dashboardUser/hooks/useFetchActivities";

const Notif = ({ isMobile }) => {

    const [openNotif, setOpenNotif] = useState(false);
    const [activeNotif, setActiveNotif] = useState(1);

    const [newNotif, setNewNotif] = useState([]);
    const [archiveNotif, setArchiveNotif] = useState([]);

    const {data: listNoticiations} = useFetchActivities();

    useEffect(() => {
        const filterNotifNew = listNoticiations.filter( item => item.status === "new");
        const filterNotifRead = listNoticiations.filter( item => item.status === "read");

        setNewNotif(filterNotifNew);
        setArchiveNotif(filterNotifRead);

    }, [listNoticiations]);

    // Notifications Menu
    const handleClickNotif = () => {
        setOpenNotif(!openNotif);
    }

    const notifMenuRef = useRef(null);
    // Outside Element Click Detection
    useCloseOnOutsideClick(notifMenuRef, setOpenNotif)

    const handleClickActiveMenuNotif = (idNotif) => {
        setActiveNotif(idNotif);
    }

    return (
        <>
            <motion.button
                className="border border-slate-400 bg-slate-transparent h-8 w-8 rounded-full relative"
                onClick={handleClickNotif}
                whileTap={{scale: 0.97}}
                whileHover={{scale: 1.04}}
            >
                <span
                    className={`w-full h-full flex justify-center items-center text-md text-slate-400 `}><FiBell/></span>
                {newNotif.length > 0 &&
                    <span className="flex h-2 w-2 absolute top-0 right-0">
                              <span
                                  className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 bg-red-500`}></span>
                              <span className={`relative inline-flex rounded-full h-2 w-2 bg-red-400`}></span>
                            </span>
                }
            </motion.button>

            <AnimatePresence>
                {openNotif &&
                    <motion.div
                        className={`bg-white md:w-72 w-full drop-shadow-md top-20 absolute right-0 ${isMobile && "left-0"} h-96 flex flex-col rounded-3xl overflow-hidden`}
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 10}}
                        transition={{type: "spring", stiffness: 300, damping: 24, delay: .2}}
                        ref={notifMenuRef}
                    >
                        <div
                            className="bg-slate-300 border border-slate-200 h-14 flex items-center justify-center text-lg font-semibold px-10 w-full">
                            <p className="text-slate-700 text-sm text-center">Notification <span>({activeNotif === 1 ? newNotif.length : archiveNotif.length})</span>
                            </p>
                        </div>
                        <div
                            className={`grow overflow-y-auto overflow-x-hidden ${newNotif.length === 0 && "flex items-center justify-center"}`}>

                            {activeNotif === 1 &&
                                (newNotif.length === 0 ?
                                        <p className="text-slate-500 text-center text-sm flex items-center justify-center flex-col">
                                            <span
                                                className="text-md text-slate-400 border border-slate-200 rounded-full flex items-center justify-center w-10 h-10"><FiArchive/></span>
                                            No new notifications
                                        </p>
                                        :
                                        <ul className="flex flex-col items-center justify-start h-full w-full text-left gap-0 divide-y divide-slate-200">
                                            {newNotif.map(item =>
                                                <li key={item.id}
                                                    className={`relative transition ease-linear hover:bg-slate-50 px-4 py-2 cursor-pointer w-full`}>
                                                    <p className="text-slate-700 leading-5 text-sm">{item.content}</p>
                                                    <span
                                                        className="text-xs text-slate-500 font-medium">{item.date}</span>
                                                </li>
                                            )}
                                        </ul>
                                )

                            }
                            {activeNotif === 2 &&
                                (archiveNotif.length === 0 ?
                                        <p className="text-slate-500 text-center text-sm flex items-center justify-center flex-col">
                                                <span
                                                    className="text-md text-slate-400 border border-slate-200 rounded-full flex items-center justify-center w-10 h-10"><FiArchive/></span>
                                            No notifications archived
                                        </p>
                                        :
                                        <ul className="flex flex-col items-center justify-start h-full w-full text-left gap-0 divide-y divide-slate-200">
                                            {archiveNotif.map(item =>
                                                <li key={item.id}
                                                    className={`relative transition ease-linear hover:bg-slate-50 px-4 py-2 cursor-pointer w-full ${item.status === "new" ? "bg-zinc-100" : "bg-white"}`}>
                                                    <p className="text-slate-700 leading-5 text-sm">{item.content}</p>
                                                    <span
                                                        className="text-xs text-slate-500 font-medium">{item.date}</span>
                                                </li>
                                            )}
                                        </ul>
                                )
                            }
                        </div>
                        <div className="h-8">
                            <ul className="flex items-center justify-center h-full">
                                {dataNotifMenuAuth.map(item =>
                                    <li
                                        key={item.id}
                                        className={`text-sm w-1/2 text-center h-full flex items-center justify-center relative cursor-pointer ${activeNotif === item.id ? "bg-white text-blue-500 font-semibold" : "text-slate-500 font-medium bg-slate-100"}`}
                                        onClick={() => handleClickActiveMenuNotif(item.id)}
                                    >
                                        {item.title}
                                    </li>
                                )}
                            </ul>
                        </div>

                    </motion.div>
                }
            </AnimatePresence>
        </>
    );
};

export default Notif;
