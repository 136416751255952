import React from "react";

// Design
import {AnimatePresence, motion} from "framer-motion";
import {Box, Button} from "@mui/material";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import {AiOutlineCloseCircle} from "react-icons/ai";
import Alert from "@mui/material/Alert";
import './index.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Autoplay} from "swiper/modules";
import SwiperCore from 'swiper';

// Custom Hooks
import {useMobile} from "../../hooks/useMobile";

SwiperCore.use([Navigation, Autoplay]);


export const NotificationBarView = (props) => {

    const {maintenancesData, showNotificationBar, handleToggleBar} = props;

    const headerElement = document.querySelector('header');
    const headerElementHeight = headerElement?.clientHeight;

    const isMobile = useMobile();

    return (
        <AnimatePresence>
            <motion.article component={"article"}
                            className={`w-full bg-red-50 relative`}
                            initial={{y: -60, opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            transition={{duration: .3}}
                            exit={{y: -60, opacity: 1}}
                            style={{ top: isMobile ? headerElementHeight + 'px' : 0 }}
            >
                <div className={"container"}>
                    <div className={"wrapper relative"} style={{width: isMobile ? (100 + '%') : 'revert-layer'}}>
                        {showNotificationBar && (
                            <div className={'notification_bar relative flex justify-center items-center min-h-[50px]'}>
                                <Swiper
                                    slidesPerView={1}
                                    grabCursor={true}
                                    pagination={false}
                                    navigation={{prevEl: ".arrow-left", nextEl: ".arrow-right"}}
                                    last={String(true)}
                                    autoplay={{delay: 5000}}
                                    speed={1000}
                                    className="notification_bar-swiper w-[90%] lg:w-[80%]"
                                    wrapperClass={"flex items-center"}

                                >
                                    {maintenancesData?.map((item) => (
                                        <SwiperSlide key={item.id}>
                                            <div
                                                className={"w-full h-full flex flex-wrap justify-center items-center px-8 py-2 lg:py-3 text-[14px]"}
                                                dangerouslySetInnerHTML={{__html: item.message}}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>

                                <Box component={"div"}
                                     className={`w-full lg:w-[80vw] max-w-[94%] md:max-w-[90%] lg:max-w-[84%] h-full flex absolute bottom-0 `}
                                >
                                    <motion.button
                                        whileHover={{scale: 1.1}}
                                        type="button"
                                        className="arrow-left arrow mr-auto relative z-10"
                                        title="Previous Tab"
                                    >
                                        <BsChevronLeft className={'text-slate-500 text-[20px] lg:text-[32px]'}/>
                                    </motion.button>

                                    <motion.button whileHover={{scale: 1.1}}
                                                   type="button"
                                                   className="arrow-right arrow ml-auto relative z-10"
                                                   title="Next Tab"
                                    >
                                        <BsChevronRight
                                            className={'text-slate-500 text-[20px] lg:text-[32px]'}/>
                                    </motion.button>

                                </Box>
                            </div>
                        )}

                        <Button sx={{
                            display: 'flex',
                            position: 'absolute',
                            top: -2,
                            right: 4,
                            ...!isMobile && (
                                {right: 0}
                            ),
                            padding: 0,
                            margin: 0,
                            minWidth: 'unset',
                            zIndex: 10,
                        }}
                                onClick={handleToggleBar}
                                type={"button"}
                        >

                            <div
                                className={`p-2 border border-solid ${!showNotificationBar ? 'border-slate-100 rounded-b-lg bg-red-50' : 'border-red-50'}`}>
                                {showNotificationBar ? (
                                    <AiOutlineCloseCircle size={26} className={'text-red-500 relative'}/>
                                ) : (
                                    <Alert severity="error"
                                           sx={{
                                               padding: 0,
                                               justifyContent: 'center',
                                               alignItems: 'center',
                                               background: 'none',
                                               '& .MuiAlert-icon': {
                                                   padding: '0',
                                                   margin: '0',
                                                   fontSize: "26px",
                                               }
                                           }}
                                    />
                                )}
                            </div>

                        </Button>
                    </div>
                </div>
            </motion.article>
        </AnimatePresence>

    )
}