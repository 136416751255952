import AxiosService from "./AxiosService";

const urlMaintenances = 'api/maintenances';

class MaintenanceService {
    getMaintenances(filters) {
        return AxiosService.getWithoutToken(urlMaintenances + `${filters ? filters : '/'}`, 'jsonld');
    }
}

export default MaintenanceService;