import {createContext, useState} from "react";


export const CatalogContext = createContext(null);

export const CatalogContextProvider = ({ children }) => {
    const [catalogType, setCatalogType] = useState(null);
    return (
        <CatalogContext.Provider value={{catalogType, setCatalogType}}>
            {children}
        </CatalogContext.Provider>
    )
}