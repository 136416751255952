import {useContext, useEffect} from 'react';
import {CatalogContext} from "../contexts/CatalogContext";

const useCatalogContext = (style, type, intensity ) => {
    const { setCatalogType, catalogType } = useContext(CatalogContext);

    let styleCatalog;

    useEffect(() => {
        setCatalogType(type);
    }, [type]);

    switch (catalogType) {
        case 'services':
            styleCatalog = style === "text" ? `text-blue-500` : `bg-blue-${intensity ? 500 : 50}  ${intensity === 500 ? "border-none text-slate-50" : "text-blue-500 border-blue-300"}`;
            break;
        case 'installations':
            styleCatalog = style === "text" ? `text-orange-500` : `bg-orange-${intensity ? 500 : 50} ${intensity === 500 ? "border-none text-slate-50" : "text-orange-500 border-orange-300"}`;
            break;
        default:
            styleCatalog = style === "text" ? `text-blue-500` : `bg-blue-${intensity ? 500 : 50}  ${intensity === 500 ? "border-none text-slate-50" : "text-blue-500 border-blue-300"}`;
    }

    return { styleCatalog }
};

export default useCatalogContext;
