import {alpha, styled} from "@mui/material/styles";
import {Switch} from "@mui/material";
import {orange} from "@mui/material/colors";

export const PlatformListSwitch = styled(Switch)(({ theme }) => ({
    '&.MuiSwitch-root': {
        width: '70px'
    },
    '& .MuiSwitch-switchBase > .MuiSwitch-thumb': {
        color: 'white',
        boxShadow: 'none',
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        height: '26px',
        position: 'relative',
        top: -6,
        left: -6,
        borderRadius: '20px',
        opacity: '0.2'
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: orange[600],
        '&:hover': {
            backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: orange[400],
        opacity: '1'
    },
}));

