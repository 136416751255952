import {useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import {useFetchOneServices} from "../hooks/useFetchOneService";
import {AnimatePresence, motion} from "framer-motion";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";

import {Tables} from "../../../components/tables/Tables";
import {CustomBreadcrumbs} from "../../../components/breadcrumb/CustomBreadcrumbs";
import {Loader} from "../../../components/loader/Loader";
import {truncateTextLetter} from "../../../utils/truncateText";
import HeadingDescriptif from "../../catalog/components/HeadingDescriptif";
import BoxListDescriptif from "../../catalog/components/BoxListDescriptif";
import ItemDescriptif from "../../catalog/components/ItemDescriptif";
import LeftMenu from "../../catalog/components/menu/LeftMenu";
import Pagination from "../../../components/pagination/Pagination";
import MessageCustom from "../../../components/message/MessageCustom";
import {isPPARoute} from "../../../utils/variables";
import {ButtonPpa} from "../../ppa/components/button/ButtonPpa";

export const Service = () => {
    const { idcatalog, id } = useParams();

    const [activeLeftMenu, setActiveLeftMenu] = useState(1);
    const [menuSelect, setMenuSelect] = useState(null);

    const [isVisible, setIsVisible ] = useState(true);
    const [sliceNb, setSliceNb] = useState(12)
    const [openKeyword, setOpenKeyword] = useState(false);
    const [contactActive, setContactActive] = useState(0);

    const { data, isLoading, error } = useFetchOneServices(idcatalog, id);

    const currentContact = data?.contact || [];

    // Resource
    const res = data.listResources;

    useEffect(() => {
        // Check if listResources is not undefined and not null
        if (res && typeof res === 'object') {
            // Check if all elements in the listResources array are empty
            const allEmpty = Object.values(res).every(item => !item || Object.keys(item)?.length === 0);

            // If all elements are empty, set isVisible to false
            if (allEmpty) {
                setIsVisible(false);
            }
        } else {
            // If listResources is undefined or null, set isVisible to false
            setIsVisible(true);
        }
    }, [res]);

    const mylinkData = [

        {
            id:3,
            title:"Service catalog",
            link: "#"
        },
        {
            id:4,
            title:data?.name,
            link:"#"
        }
    ]



    const handleClickMenu = (menuId) => {
        if (menuId !== 3) return setActiveLeftMenu(menuId);
        window.confirm("Are you sur to submit the preposal ProjectProposal?");
    }

    if (error){
        return <Navigate to='/notfound'/>
    }

    if (isLoading) {
        return <Loader content="Loading descriptive page" />
    }

    const keywordFilter = data?.keywords?.slice(0, sliceNb);

    const handleKeyword = () => {
        if (!openKeyword) {

            const lengthKey = data?.keywords?.length;
            setSliceNb(lengthKey)

            setOpenKeyword(true)
        } else {
            setSliceNb(12);
            setOpenKeyword(false)
        }
    }

    return (
        <AnimatePresence>
            { !isLoading &&
                <motion.article
                    id="section"
                    className={`min-h-screen w-full flex lg:flex-row flex-col items-stretch lg:gap-2 bg-slate-100`}

                    initial={{opacity: 0}}
                    transition={{type: "spring", duration: .5, ease: "easeIn"}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    {/*Left Box*/}
                    <div className="lg:w-1/5 w-full grow py-10 flex items-stretch justify-center gap-4 bg-white shadow-sm">
                        <div className={`lg:w-9/12 w-11/12 flex flex-col gap-2`}>
                            {/* Title */}
                            <h2 className={`lg:text-3xl text-xl font-medium fontfamilly`}>{data.name}</h2>
                            {/* Tagline */}
                            {!data.tagLine ?
                                ""
                                :
                                <p className={`text-sm text-slate-500`}>{data.tagLine}</p>
                            }
                            {/* BreadCrumb */}
                            <div className="order-first border-b border-slate-200 lg:h-12 h-16">
                                <CustomBreadcrumbs linkData={mylinkData} catalogType="services" />
                            </div>

                            {/* Image preview */}
                            <motion.div
                                className=" w-full lg:h-[500px] h-[550px] overflow-hidden my-4 border border-slate-200 rounded-xl shadow-sm shadow-slate-200"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}

                                transition={{type: "spring", duration: .5, ease: "easeIn"}}
                            >
                                <figure className="lg:h-[500px] h-[550px]">
                                    <img
                                        src={data.mainImage || data.mainImage === "" ? `${process.env.REACT_APP_API_URL}${data.mainImage}` : `/uploads/update.jpg`}
                                        alt={`${data.name}`}
                                        className="w-full h-full rounded-lg object-cover"
                                    />
                                </figure>
                            </motion.div>

                            {/* description */}
                            {data.description &&
                                <div className={`flex flex-col gap-4 mb-4`}>
                                    <HeadingDescriptif title="Description of our service" />
                                    <div className="text-slate-700" dangerouslySetInnerHTML={{__html: data.description}}/>
                                </div>
                            }

                            {/* Specifique Infos */}
                            {data.functionalities &&
                                <div className="flex flex-col gap-4 mb-4">
                                    <HeadingDescriptif title="Specific Information" />
                                    <div className={`text-slate-700`} dangerouslySetInnerHTML={{__html: data.functionalities}}/>
                                </div>
                            }
                            {/* Resource */}
                            {isVisible &&
                                <div className="flex flex-col gap-4 mb-4">
                                    <HeadingDescriptif title="Our resources" />
                                    <div className={`flex flex-col gap-4 overflow-y-hidden overflow-x-auto relative`}>
                                        <Tables tab={data.listResources}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {/* Right Box */}
                    <div className="lg:w-1/3 w-full lg:p-4 flex gap-8 rounded-2xl shadow-sm shadow-slate-300 relative">
                        <div className="lg:fixed right-0 top-0 lg:w-1/3 w-full flex flex-col gap-0 overflow-hidden shadow-sm">
                            <LeftMenu
                                type="services"
                                activeLeftMenu={activeLeftMenu}
                                menuSelect={menuSelect}
                                setMenuSelect={setMenuSelect}
                                handler={handleClickMenu}
                            />
                            <div className={`flex flex-col gap-4 w-full grow pb-10 relative lg:h-screen min-h-screen bg-white pt-10`}>

                                {activeLeftMenu === 1 &&
                                    <div
                                        className={`lg-w-9/12 flex flex-col p-8 items-start gap-8 lg:h-screen w-full lg:overflow-y-auto`}>

                                        {/* General Infos */}
                                        <div className="flex flex-col gap-2 w-full">
                                            <p className="text-md font-semibold text-slate-700 mb-2 flex items-center gap-2">General</p>

                                            <div className="flex items-start gap-4 flex-wrap">
                                                {data.logo &&
                                                    <div className=" h-12 w-32">
                                                        <figure className=" h-12 w-32">
                                                            <img
                                                                src={`${process.env.REACT_APP_API_URL}${data.logo}`}
                                                                alt={`logo du ${data.title}`}
                                                                className="w-full h-full object-contain"/>
                                                        </figure>
                                                    </div>
                                                }
                                                <div
                                                    className={`flex items-center gap-2 h-12  px-4 py-2 border border-slate-200 grow rounded-md`}>
                                                    <p className={`text-sm text-slate-500 font-medium fontfamilly min-w-1/5`}>Provider</p>
                                                    <p title={data.provider}
                                                       className={`text-sm text-slate-700 w-1/5 grow`}
                                                    >{data.provider ? truncateTextLetter(data.provider, 55) : <span
                                                        className="text-xs border border-slate-200 bg-slate-50 text-slate-500 px-2">N/A</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            <BoxListDescriptif>
                                                <ItemDescriptif dataTitle="Institution" dataInfo={data.institution}/>
                                                <ItemDescriptif dataTitle="Installation" dataInfo={data.infraName}
                                                                path={data.infraId} id={id} type="installation"/>
                                                <ItemDescriptif dataTitle="Website"
                                                                dataInfo={truncateTextLetter(data.website, 55)}
                                                                path={data.website}/>
                                                <ItemDescriptif dataTitle="Country" dataInfo={data.location?.country}/>
                                                <ItemDescriptif dataTitle="Region" dataInfo={data.location?.region}/>
                                                <ItemDescriptif dataTitle="City" dataInfo={data.location?.city}/>
                                                <ItemDescriptif dataTitle="Street" dataInfo={data.location?.street}/>
                                            </BoxListDescriptif>
                                        </div>

                                        {/* ALL keywords */}
                                        {!data.keywords || data.keywords?.length === 0 ?
                                            ""
                                            :
                                            <div className="flex flex-col gap-4 w-full">
                                                <p className="text-md font-semibold text-slate-700 mb-2 flex items-center gap-2">Keywords</p>
                                                <div
                                                    className={`flex items-center justify-center w-full min-h-20 bg-none border-dashed border border-slate-300 rounded-md relative`}>
                                                    <ul className="flex flex-wrap gap-2 p-4 w-full">
                                                        {keywordFilter.map((item, index) =>
                                                            <li key={index}
                                                                className="flex items-center justify-center p-3 h-[20px] bg-slate-100 rounded-2xl text-xs text-gray-500">{item}</li>
                                                        )}
                                                    </ul>
                                                    {data?.keywords?.length > 12 &&
                                                        <button onClick={handleKeyword}
                                                                className="border border-solid border-blue-300 bg-blue-50 text-blue-500 text-lg font-bold flex items-center justify-center rounded-full absolute -bottom-2">
                                                            {openKeyword ? <FiChevronUp/> : <FiChevronDown/>}
                                                        </button>
                                                    }
                                                </div>

                                            </div>
                                        }


                                        {/* Info Contact */}
                                        {data?.contact?.length > 0 &&
                                            <div className="flex flex-col gap-4 w-full">
                                                <div className="flex justify-start gap-4">
                                                    <p className="text-md font-semibold text-slate-700 mb-2 flex items-center gap-2">Contact</p>
                                                    <Pagination dataLength={data?.contact?.length}
                                                                setContactActive={setContactActive}/>
                                                </div>
                                                <BoxListDescriptif>
                                                    <ItemDescriptif dataTitle="Lastname"
                                                                    dataInfo={currentContact[contactActive]?.lastname}/>
                                                    <ItemDescriptif dataTitle="Firstname"
                                                                    dataInfo={currentContact[contactActive]?.firstname}/>
                                                    <ItemDescriptif dataTitle="Position"
                                                                    dataInfo={currentContact[contactActive]?.position}/>
                                                    <ItemDescriptif dataTitle="Organisation"
                                                                    dataInfo={currentContact[contactActive]?.organisation}/>
                                                    <ItemDescriptif dataTitle="Phone"
                                                                    dataInfo={currentContact[contactActive]?.phone}/>
                                                    <ItemDescriptif dataTitle="Email"
                                                                    dataInfo={currentContact[contactActive]?.email}
                                                                    email={currentContact?.email}/>
                                                </BoxListDescriptif>
                                            </div>
                                        }
                                    </div>
                                }

                                {activeLeftMenu === 2 && (
                                    (data.scientificDomain?.length === 0 && data.scientificSubDomain?.length === 0 && data.targetUsers?.length === 0 && data.accessType?.length === 0 && data.accessMode?.length === 0) ?
                                        (
                                            <MessageCustom/>
                                        )
                                        :
                                        (
                                            <div className={`lg-w-9/12 grid lg:grid-cols-2 grid-cols-1 p-8 gap-4 w-full lg:overflow-y-auto`}>
                                                <ul className="flex flex-col gap-2 w-full">
                                                    <li className="flex flex-col w-full border rounded-xl overflow-hidden">
                                                        <div className="bg-slate-100 h-10 text-sm text-slate-700 flex items-center justify-start px-4">
                                                            <p className="text-sm font-semibold text-slate-700">Target
                                                                Users</p>
                                                        </div>

                                                        {data.targetUsers?.length > 0 ?
                                                            <BoxListDescriptif border={false}>
                                                                {data.targetUsers.map(item =>
                                                                    <ItemDescriptif title={item}
                                                                                    dataInfo={truncateTextLetter(item, 32)}/>
                                                                )}
                                                            </BoxListDescriptif>
                                                            :
                                                            <li className={`border border-slate-200 rounded-2xl h-24 flex items-center justify-center`}>
                                                                <span className="text-sm text-slate-500">Not Set</span>
                                                            </li>
                                                        }
                                                    </li>
                                                    <li className="flex flex-col w-full border rounded-xl overflow-hidden">
                                                        <div className="bg-slate-100 h-10 text-sm text-slate-700 flex items-center justify-start px-4">
                                                            <p className="text-sm font-semibold text-slate-700">Target Type</p>
                                                        </div>
                                                        {data.accessType?.length > 0 ?
                                                            <BoxListDescriptif border={false}>
                                                                {data.accessType.map(item =>
                                                                    <ItemDescriptif title={item}
                                                                                    dataInfo={truncateTextLetter(item, 32)}/>
                                                                )}
                                                            </BoxListDescriptif>
                                                            :
                                                            <li className={`border border-slate-200 rounded-2xl h-24 flex items-center justify-center`}>
                                                                <span className="text-sm text-slate-500">Not Set</span>
                                                            </li>
                                                        }
                                                    </li>
                                                    <li className="flex flex-col w-full border rounded-xl overflow-hidden">
                                                        <div
                                                            className="bg-slate-100 h-10 text-sm text-slate-700 flex items-center justify-start px-4">
                                                            <p className="text-sm font-semibold text-slate-700">Access
                                                                Mode</p>
                                                        </div>
                                                        {data.accessMode?.length > 0 ?
                                                            <BoxListDescriptif border={false}>
                                                                {data.accessMode.map(item =>
                                                                    <ItemDescriptif title={item}
                                                                                    dataInfo={truncateTextLetter(item, 32)}/>
                                                                )}
                                                            </BoxListDescriptif>
                                                            :
                                                            <li className={`border border-slate-200 rounded-2xl h-24 flex items-center justify-center`}>
                                                                <span className="text-sm text-slate-500">Not Set</span>
                                                            </li>
                                                        }
                                                    </li>
                                                </ul>
                                                <ul className="flex flex-col gap-4 w-full">
                                                    <li className="flex flex-col w-full border rounded-xl overflow-hidden">
                                                        <div
                                                            className="bg-slate-100 h-10 text-sm text-slate-700 flex items-center justify-start px-4">
                                                            <p className="text-sm font-semibold text-slate-700">Scientific
                                                                Domains</p>
                                                        </div>
                                                        {data.scientificDomain?.length > 0 ?
                                                            <BoxListDescriptif border={false}>
                                                                {data.scientificDomain.map(item =>
                                                                    <ItemDescriptif title={item}
                                                                                    dataInfo={truncateTextLetter(item, 52)}/>
                                                                )}
                                                            </BoxListDescriptif>
                                                            :
                                                            <li className={`border border-slate-200 rounded-2xl h-24 flex items-center justify-center`}>
                                                                <span className="text-sm text-slate-500">Not Set</span>
                                                            </li>
                                                        }
                                                    </li>
                                                    <li className="flex flex-col w-full border rounded-xl overflow-hidden">
                                                        <div
                                                            className="bg-slate-100 h-10 text-sm text-slate-700 flex items-center justify-start px-4">
                                                            <p className="text-sm font-semibold text-slate-700">Scientific
                                                                SubDomains</p>
                                                        </div>
                                                        {data.scientificSubDomain?.length > 0 ?
                                                            <BoxListDescriptif border={false}>
                                                            {data.scientificSubDomain.map(item =>
                                                                    <ItemDescriptif title={item}
                                                                                    dataInfo={truncateTextLetter(item, 32)}/>
                                                                )}
                                                            </BoxListDescriptif>
                                                            :
                                                            <li className={`border border-slate-200 rounded-2xl h-24 flex items-center justify-center`}>
                                                                <span className="text-sm text-slate-500">Not Set</span>
                                                            </li>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                )
                                }

                                {isPPARoute &&
                                    <ButtonPpa catalog="services"/>
                                }
                            </div>

                        </div>
                    </div>

                </motion.article>
            }
        </AnimatePresence>

    )
}