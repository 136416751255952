import {useEffect, useState} from "react";

// Services
import MaintenanceService from "../../../services/MaintenanceService";

export const useFetchMaintenances = (filters) => {

    const maintenanceService = new MaintenanceService();
    const [maintenancesData, setMaintenancesData] = useState([]);

    useEffect(() => {

        async function handleFetchMaintenances(){
            try {

                const response = await maintenanceService.getMaintenances(filters);
                const hydraMember = response["hydra:member"];
                setMaintenancesData(hydraMember);

            } catch (error) {
                console.error(error);
            }
        }
        handleFetchMaintenances().then();

    }, [filters]);

    return { maintenancesData }
}