import React from 'react';
import {motion} from "framer-motion";
import {LoadMore} from "../../../components/button/LoadMore";
import {useContext} from "react";
import {CompConfigContext} from "../../../components/CompConfig";
import {FiArrowUpRight} from "react-icons/fi";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {toast} from "sonner";
import Tooltip from '@mui/material/Tooltip'


const selectUser = createSelector(
    state => state.login.user,
    user => user
);


export const ServiceType = () => {
    const data = useContext(CompConfigContext);
    const user = useSelector(selectUser);

    return (
        <div className="pb-10 min-h-[700px] flex items-start justify-center">
            <div className="container">
                <div className={`wrapper relative flex flex-col items-start gap-4`}>
                    { data.error ?
                        <div className="min-h-[200px] flex items-center justify-center w-full">
                            <p className="text-slate-500 text-center text-md font-bold">Ooops! we have a
                                problem {data.error.message}</p>
                        </div>
                        :
                        <>
                            {!data.myData || data.myData.length === 0 ?

                                <div className="min-h-[200px] flex items-center justify-center w-full">
                                    <p
                                        className="text-slate-500 text-center text-sm"
                                    >
                                        No results
                                    </p>
                                </div>
                                :
                                <>
                                    <motion.ul
                                        className={`grid w-full mt-20 ${data.gridMode ? 'grid-cols-4 gap-8' : 'grid-cols-1 gap-2'}`}
                                        initial={{opacity: 0, translateY: 100}}
                                        animate={{opacity: 1, translateY: 0}}
                                        exit={{opacity: 0, translateY: 100}}
                                        transition={{type: "spring", duration: .6, ease: "easeOut"}}
                                    >
                                        {data.myData.map(item =>
                                            <motion.li
                                                className={`group cursor-pointer relative border border-slate-200 rounded-2xl flex flex-col`}
                                                key={item.id}
                                                onClick={(e) => {

                                                    e.stopPropagation();

                                                    if (data.role?.canUpdateUsers || user?.isSuperAdministrator ) {
                                                        data.handleClickOpenDrawer("UPDATE", item.id)
                                                    } else {
                                                        return toast.info("Access to this resource is restricted due to insufficient rights.")
                                                    }
                                                }}
                                            >
                                                <div className={`bg-slate-100 rounded-t-2xl text-slate-700 relative w-full order-first h-14 flex items-center justify-center`}>
                                                    <p className="group-hover:text-blue-500 transition ease-linear text-sm text-slate-700 font-bold flex gap-2 items-center text-center">
                                                        {item.name}
                                                    </p>
                                                </div>

                                                <div className={`h-24 start flex items-center justify-center px-6`}>

                                                    <ul className={`flex flex-col gap-4 w-full text-center`}>
                                                        <li className={`flex items-center justify-between`}>
                                                            <p className="text-sm text-slate-700">
                                                                Responsable
                                                            </p>
                                                            <span
                                                                className={`border border-slate-200 rounded-sm h-6 px-4 text-xs flex items-center justify-center`}>
                                                            {item.responsable.fullName}
                                                            </span>
                                                        </li>
                                                        <li className={`flex items-center justify-between`}>
                                                            <p className="text-sm text-slate-700">
                                                                Visible in catalog
                                                            </p>
                                                            <Tooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        {item.listNetworksToDisplay.length > 0 ?
                                                                            item.listNetworksToDisplay.map((n) => n.name).join(', ')
                                                                        : "No networks selected"
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                children={
                                                                    <span
                                                                        className={`border rounded-sm h-6 px-4 text-xs flex items-center justify-center font-medium ${(item.listNetworksToDisplay.length > 0) ? 'border-green-200 bg-green-50 text-green-500' : 'border-slate-200 bg-slate-50 text-slate-500'}`}>
                                                                            {(item.listNetworksToDisplay.length > 0) ? 'Visible : ' + item.listNetworksToDisplay.length : 'Hidden'}
                                                                    </span>
                                                                }
                                                            />
                                                        </li>
                                                    </ul>

                                                </div>

                                                <button
                                                    onClick={ (e) => {
                                                        e.stopPropagation()
                                                        window.open('/config/servicetypes/description/' + item.id, '_blank');
                                                    }}
                                                    title={`See the description ${item.name}`}
                                                    className="z-10 border border-slate-200 text-slate-500 h-8 bg-slate-50 hover:bg-blue-500 hover:text-slate-50 transition ease-linear flex items-center justify-center text-xs px-2">
                                                    See the description <FiArrowUpRight />
                                                </button>
                                            </motion.li>
                                        )}
                                    </motion.ul>

                                    <LoadMore
                                        dataLoad={4}
                                        itemsPerPage={data.itemsPerRow}
                                        setItemsPerPage={data.setItemsPerRow}
                                        totalItems={data.totalItem}
                                    />
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}