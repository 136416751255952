import {useForm, useWatch} from "react-hook-form";
import {toast} from "sonner";
import {motion} from "framer-motion";
import {FiX} from "react-icons/fi";
import FormService from "../../service/FormService";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as React from "react";
import {isInt} from "@fullcalendar/core/internal";
import ErrorMessageRF from "../../../../components/form/errorMessage/ErrorMessageRF";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);


const CreateForm = ({ setIsOpenTabs, setOpenEditBox, method, type, id, idTab, data, setOpenFormFormsId, setSubmitting }) => {

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            columnNumber: 1,
            position: 1,
            isLocked: true,
            isFromConfig: true,
            isHidden: true,
            typeOfForm: null,
            backgroundColor: null,
            tab:null,
            resourceType: null
        }
    })

    const user = useSelector(selectUser);
    const token = user.token;

    useEffect(() => {
        if (idTab) {
            setValue('tab', `api/tabs/${idTab}`);
        }
    }, [idTab, setValue]);

    const onSubmit = async (data) => {

        data.position = parseInt(data.position, 10);
        data.columnNumber = parseInt(data.columnNumber, 10);
        data.isHidden = Boolean(data.isHidden);
        data.isLocked = Boolean(data.isLocked);
        data.typeOfForm = (String(data.typeOfForm) === "null") ? null : Number(data.typeOfForm);

        try {
            const requestData = {
                name: data.name,
                columnNumber: data.columnNumber,
                position: data.position,
                isLocked: data.isLocked,
                isFromConfig: data.isFromConfig,
                isHidden: data.isHidden,
                typeOfForm: data.typeOfForm,
                backgroundColor: data.backgroundColor,
                tab:data.tab,
                resourceType: data.resourceType ? `api/resource_types/${id}` : null,
            }

            const formService = new FormService();

            const res = await formService.AddForm(requestData, token);

            if(res) {

                setIsOpenTabs(false);
                setOpenFormFormsId(null);
                setSubmitting(true);

                return toast.success("Form added successfully!");
            }

        } catch (error) {
            console.error(error)
            return toast.error("Failed to add form, try later");
        }
    }

    const handleCloseTabs = () => {
        setOpenFormFormsId(null)
    }

    return (
        <motion.form
            className="font-medium rounded-xl text-slate-500 min-h-20 min-w-60 flex flex-col items-center justify-start gap-4"

            initial={{opacity: 0, translateY: 15}}
            animate={{opacity: 1, translateY: 0}}
            exit={{opacity: 0, translateY: 15}}

            transition={{type: "spring", duration: .3, ease: "linear"}}

            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="bg-slate-100 p-4 rounded-2xl flex flex-col gap-4 w-full">
                <label
                    htmlFor="name"
                    className="mb-2 block text-sm text-slate-500"
                >{errors.name ? <ErrorMessageRF content={errors.name.message}/> : "Name of form" }</label>
                <input type="text"
                       placeholder="Enter new name of form..."
                       id="name"
                       autoFocus={true}
                       {...register("name", {required: "This field is required!"})}
                       className="bg-transparent focus:outline-none h-10 px-2 w-full"
                />

                <div className="w-full h-10 flex items-center gap-2">
                    {typeForm.map(item =>
                        <label
                            key={item.id}
                            htmlFor={`typeOfForm-${item.id}`}
                            className={`
                            h-full w-1/2 rounded-sm grow relative cursor-pointer flex items-center gap-2 border ${String(watch("typeOfForm")) !== String(item.value) ? "border-slate-200 bg-slate-100 text-slate-400" : "border-blue-400 bg-blue-100 text-blue-400"} justify-center text-xs`}
                        >
                            <input
                                type="radio"
                                id={`typeOfForm-${item.id}`}
                                value={item.value}
                                className="sr-only"
                                {...register("typeOfForm")}
                            />
                            {item.title}
                        </label>
                    )}
                </div>
            </div>

            <div className="flex flex-nowrap items-center justify-start gap-4 w-full">
                <button
                    type="submit"
                    className="bg-blue-400 border border-blue-400 text-slate-50 font-medium rounded-full p-2"
                >
                    Create form
                </button>
                <button
                    type="button"
                    className="bg-transparent hover:bg-slate-50 rounded-full p-1 text-lg border border-slate-300"
                    onClick={handleCloseTabs}
                >
                    <FiX/>
                </button>
            </div>
        </motion.form>
    );
};

export default CreateForm;

const typeForm = [
    {
        id: 1,
        value: "null",
        title: "Classic"
    },
    {
        id: 2,
        value: "0",
        title: "List"
    }
]

