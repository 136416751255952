import { Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { FaMagic } from 'react-icons/fa'

export const ChangeLog = () => {
    return (
        <motion.article
            id="change_log"
            className="min-h-screen
                       pt-24
                       lg:pt-32"
            initial={{ opacity: 0 }}
            transition={{ type: 'spring', duration: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="container">
                <div
                    className="wrapper
                            flex
                            flex-col
                            gap-10
                            lg:gap-14
                            pb-10"
                >
                    <div className="content flex flex-wrap justify-between pb-10">
                        <Typography
                            variant="h1"
                            className="title"
                            sx={{ maxWidth: { lg: '500px' }, margin: { xs: '0 0 20px', lg: '0 auto 0 0' } }}
                        >
                            Change <span className="text-blue-600/100">Log</span>
                        </Typography>

                        <h1 id="release-notes-3.0.7.20241119-">
                            Release notes (<code>3.0.7.20241119</code>)
                        </h1>
                        <h2 id="new-fetaures-3.0.7.20241119-">New features</h2>
                        <ul>
                            <li>
                                <ul>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/535"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#535]
                                        </a>{' '}
                                        Add possibility to display Service Type by networks
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements-3.0.7.20241119-">Improvements</h2>
                        <ul>
                            <li>
                                <ul>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/544"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#544]
                                        </a>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/569"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#569]
                                        </a>{' '}
                                        Improve descriptive sheet
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/549"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#549]
                                        </a>{' '}
                                        Fast access can now be opened in new tab
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/556"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#556]
                                        </a>{' '}
                                        Improve way to edit Service Type
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/560"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#560]
                                        </a>{' '}
                                        Improve public contact on descriptive sheet
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/568"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#568]
                                        </a>{' '}
                                        Improve installation menu
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed-3.0.6.2.20241107-">Bug fixed</h2>
                        <ul>
                            <li>
                                <ul>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/548"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#548]
                                        </a>{' '}
                                        Fix bug switch button on tab creation/edition
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/558"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#558]
                                        </a>{' '}
                                        Fix style on description
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/559"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#559]
                                        </a>{' '}
                                        Fix network already chosen on catalog
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/570"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#570]
                                        </a>{' '}
                                        Fix bug switch button on input creation
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-core/-/issues/72"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#72]
                                        </a>{' '}
                                        Fix form of type list update
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h1 id="release-notes-3.0.6.2.20241107-">
                            Release notes (<code>3.0.6.2.20241107</code>)
                        </h1>
                        <h2 id="improvements-3.0.6.2.20241107-">Improvements</h2>
                        <ul>
                            <li>
                                Descriptive sheet
                                <ul>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/550"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#550]
                                        </a>{' '}
                                        Remove disabled list even if not options
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/561"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#561]
                                        </a>{' '}
                                        Add link on WYSIWYG
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/562"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#562]
                                        </a>{' '}
                                        Add possibility to disable field
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed-3.0.6.2.20241107-">Bug fixed</h2>
                        <ul>
                            <li>
                                <ul>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/563"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#563]
                                        </a>{' '}
                                        Fix installation CRUD
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/547"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#547]
                                        </a>{' '}
                                        Fix tab networks not allowed
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/565"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#565]
                                        </a>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/536"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#536]
                                        </a>{' '}
                                        Fix unabled to invite user
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/564"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#564]
                                        </a>{' '}
                                        Fix user rights after losing access
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h1 id="release-notes-3.0.6.20241017-">
                            Release notes (<code>3.0.6.20241017</code>)
                        </h1>
                        <h2 id="new-features-3.0.6.20241017-">New features</h2>
                        <ul>
                            <li>
                                Networks
                                <ul>
                                    <li>Add Selects and Selects Options</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements-3.0.6.20241017-">Improvements</h2>
                        <ul>
                            <li>
                                Cosmetics / Typo
                                <ul>
                                    <li>Update style, mostly in configuration</li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/534"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#534]
                                        </a>{' '}
                                        Docs should remain visible
                                    </li>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/531"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#531]
                                        </a>{' '}
                                        Fix descriptive sheet typo
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed-3.0.6.20241017-">Bug fixed</h2>
                        <ul>
                            <li>
                                Services
                                <ul>
                                    <li>
                                        <a
                                            href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/532"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            [#532]
                                        </a>{' '}
                                        Fix issue which doesn't allow to update Service informations
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h1 id="release-notes-3.0.5.20241011-">
                            Release notes (<code>3.0.5.20241011</code>)
                        </h1>
                        <h2 id="new-features-3.0.5.20241011-">New features</h2>
                        <ul>
                            <li>
                                Installations
                                <ul>
                                    <li>Possibility to create new installation</li>
                                </ul>
                            </li>
                        </ul>

                        <h1 id="release-notes-3.0.4.20240930-">
                            Release notes (<code>3.0.4.20240930</code>)
                        </h1>
                        <h2 id="new-features-3.0.4.20240930-">New features</h2>
                        <ul>
                            <li>
                                Networks
                                <ul>
                                    <li>Fix uploading logo/main image</li>
                                    <li>Fix impossibility to edit a tab in network configuration</li>
                                </ul>
                            </li>
                            <li>
                                Invitation
                                <ul>
                                    <li>Fix bad link when register</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed-3.0.4.20240930-">Bug fixed</h2>
                        <ul>
                            <li>
                                Descriptions of installations
                                <ul>
                                    <li>
                                        Add possibility to switch between parent/child into installation description
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h1 id="release-notes-2-10-5-dd56bb58-">
                            Release notes (<code>3.0.3.20241006</code>)
                        </h1>
                        <h2 id="new-features" className="flex flex-row gap-4">
                            <FaMagic /> V3 Global Release
                        </h2>
                        <ul>
                            <li>
                                Installations User Management
                                <ul>
                                    <li>User Invite / management</li>
                                    <li>Roles management</li>
                                    <li>Teams management</li>
                                </ul>
                            </li>
                            <li>
                                Installations Resource Management
                                <ul>
                                    <li>Resource Info</li>
                                    <li>Events</li>
                                    <li>Links</li>
                                    <li>Access</li>
                                    <li>Activities</li>
                                    <li>Overview</li>
                                </ul>
                            </li>
                            <li>
                                Installations Description
                                <ul>
                                    <li>Standard description (based on EOSC)</li>
                                    <li>Additional description by network</li>
                                </ul>
                            </li>
                            <li>
                                Installation Service Type Description
                                <ul>
                                    <li>Standard description (based on EOSC)</li>
                                    <li>Additional description by network</li>
                                </ul>
                            </li>
                            <li>
                                Network Description Management
                                <ul>
                                    <li>Standard description (based on EOSC)</li>
                                    <li>Additional description by network</li>
                                </ul>
                            </li>
                            <li>Pre Proposal agreement (PPA)</li>
                            <li>
                                <a href="/catalog" target="_blank">
                                    Catalogs of Installations
                                </a>
                            </li>
                            <li>
                                <a href="/catalog" target="_blank">
                                    Catalogs of Services
                                </a>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-10-5-dd56bb58-">
                            Release notes (<code>3.0.2.20240812</code>)
                        </h1>
                        <h2 id="new-features">Catalog update</h2>
                        <ul>
                            <li>Improve catalogs filters</li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-10-5-dd56bb58-">
                            Release notes (<code>3.0.1.20240401</code>)
                        </h1>
                        <h2 id="new-features">New version of ISIA</h2>
                        <ul>
                            <li>
                                <a href="/catalog" target="_blank">
                                    Catalogs of Installations
                                </a>
                            </li>
                            <li>
                                <a href="/catalog" target="_blank">
                                    Catalogs of Services
                                </a>
                            </li>
                            <li>
                                <a href="/ppa" target="_blank">
                                    Project submission
                                </a>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-10-5-dd56bb58-">
                            Release notes (<code>2.10.5.dd56bb58</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                [GITLAB <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/387">#387</a>] PPA
                                pour Agroserv
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                [GITLAB <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/388">#388</a>]
                                Users - SuperAdmin
                            </li>
                            <li>
                                [GITLAB <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/386">#386</a>] Date
                                résa sur mails automatiques
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-10-4-58dba0bd-">
                            Release notes (<code>2.10.4.58dba0bd</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Catalog of Services</strong>
                                <ul>
                                    <li>Add catalog of services + descriptive sheet</li>
                                </ul>
                            </li>
                        </ul>

                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/382">#382</a>] Fix
                                        back button on modal error when upload image
                                    </li>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/379">#379</a>] Fix
                                        add element on custom lists
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource type</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/378">#378</a>] Fix
                                        notification error
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-10-3-3d7f5a5e-">
                            Release notes (<code>2.10.3.3d7f5a5e</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Service Types / Networks</strong>
                                <ul>
                                    <li>Add Service Type description regarding the Network description</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                **Whole Application
                                <ul>
                                    <li>[MAIL] Use mail sendmail instead of smtp</li>
                                    <li>[MAIL] Add possibility to avoid sending email</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Projects</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/374">#374</a>]
                                        Submitted project disappeared from the project list. Add new right to see all
                                        projects even if not in
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-10-2-726bb4bb-">
                            Release notes (<code>2.10.2.726bb4bb</code>)
                        </h1>
                        <h2 id="improvement">Improvement</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>Avoid for all users Resources Emails</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>PPA Custom &amp; Projects</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/371">#371</a>]
                                        Hidden fields are now well hidden
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/372">#372</a>] When
                                        displaying Links, display correct resources regardings the role
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-10-1-a8e86ac4-">
                            Release notes (<code>2.10.1.a8e86ac4</code>)
                        </h1>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Network</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/369">#369</a>]
                                        Modal delete not displayed when edit my platform
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Service Type</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/368">#368</a>]
                                        Error when add role on service type
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-10-0-2aae9433-">
                            Release notes (<code>2.10.0.2aae9433</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>PPA Custom</strong>
                                <ul>
                                    <li>[ISIA #375] Add PPA custom to each network + networks catalog</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Infra Tasks</strong>
                                <ul>
                                    <li>Infra tasks are now with recurrences</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Notifications</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/357">#357</a>.
                                        Related to{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/259">#259</a>] Add
                                        notification parameter for each user
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvement">Improvement</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>Update core packages</li>
                                    <li>Add correct page when an error occured</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Network</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/338">#338</a>]
                                        Contacts not filled. The child infrastructure is now used instead of parents.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Platform</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/354">#354</a>]
                                        Improve controls to not have grandparents of platforms
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource</strong>
                                <ul>
                                    <li>[ISIA #1011] Improve rights in resources</li>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/352">#352</a>]
                                        Block possibility to get identicals links
                                    </li>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/361">#361</a>]
                                        Pre-select the resource when edit a ticket
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Service Type</strong>
                                <ul>
                                    <li>[ISIA #1244] Add responsable for Service Type</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/348">#348</a>]
                                        Field of type Number without value (null) doesn&#39;t throw error anymore.
                                    </li>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/345">#345</a>]
                                        Export rights is now correct
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-9-8-0ff26e97-">
                            Release notes (<code>2.9.8.0ff26e97</code>)
                        </h1>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/337">#337</a>
                                        ][DUPLICATE FORM] Duplicated inputs are now editable
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource Type</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/343">#343</a>]
                                        Export form display the well the desctination tab and can be submitted
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/332">#332</a>]
                                        Invitation user list doesn&#39;t display empty option anymore
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/330">#330</a>]
                                        &quot;Delete&quot; keyboard button when rename resource now delete only
                                        caracters instead of the resource
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Platform</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/339">#339</a>]
                                        Improve controls when edit Platform Default Description
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Network</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/338">#338</a>.
                                        Related to{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/341">#341</a>]
                                        Informations not filled. The child infrastructure is now used instead of
                                        parents.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-9-7-bebfc504-">
                            Release notes (<code>2.9.7.bebfc504</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>[PLATFORM] Add filter button when try to select platform</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/323">#323</a>
                                        ][GANTT] Improve Gantt with saving filters, view and positionX,Y. Order with
                                        older first. Improve CSS label
                                    </li>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/309">#309</a>] Add
                                        possibility to move form in different tab in the same step. Works for
                                        ServiceType as well
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Profile</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/325">#325</a>] Fix
                                        orcid issue
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>My Dashboard</strong>
                                <ul>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/327">#327</a>] Fix
                                        my personal tasks issue which doesn&#39;t display users shared
                                    </li>
                                    <li>
                                        [GITLAB{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/324">#324</a>] :
                                        User list in dashboard is now ordered and empty entry are removed
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Network</strong>
                                <ul>
                                    <li>Network accesses are now allowed by admin/SA and network manager</li>
                                    <li>Improve network viewing and session platform</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource</strong>
                                <ul>
                                    <li>
                                        [TICKET{' '}
                                        <a href="https://support.isia.cnrs.fr/isia/isia-web/-/issues/326">#326</a>]
                                        Duplicate Ressource : Fix bug which thrown an error when duplicate ressource
                                        with image
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-9-6-e36d650c-">
                            Release notes (<code>2.9.6.e36d650c</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Whole Application</strong>
                                <ul>
                                    <li>[TICKET #318][EXPORT] Exporting forms is now possible</li>
                                    <li>[STYLE] Improve style modals and add icon&#39;s buttons</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>[TICKET #306] Display tasks finished in the box &quot;Tasks&quot;</li>
                                </ul>
                            </li>
                            <li>
                                <strong>User</strong>
                                <ul>
                                    <li>[PROFIL] Add Orc id</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                <strong>Whole Application</strong>
                                <ul>
                                    <li>[STYLE] Improve style for Loading tabs</li>
                                    <li>
                                        [GITLAB #291][MODAL] Improve modal design and avoid to click outside the box to
                                        prevent errors
                                    </li>
                                    <li>
                                        [MENU_PROJET][GITLAB #311] Set last projects to 10 and order it by desc on
                                        updateDate
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [GANTT][GITLAB #320] Improve Gantt style, hide finished tasks by default, group
                                        tasks by parents
                                    </li>
                                    <li>
                                        [GITLAB #307] Display Step information on Workflow to display only if tasks are
                                        here or not. Same for forms
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>My Dashboard</strong>
                                <ul>
                                    <li>[TICKET #321] Improve my_dashboard and Personal tasks are now visibled</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource</strong>
                                <ul>
                                    <li>Improve resource ajax loading to load it only if tab is selected</li>
                                    <li>[STYLE] Improve style and traductions on ressource export/duplicate</li>
                                    <li>
                                        [JSTREE][GITLAB #260]Keyboard shortcut. Add possibility to rename (F2) or delete
                                        (suppr) a node in jstree.
                                    </li>
                                    <li>
                                        [JSTREE][GITLAB #256]Add number of child elements at the end of the line in
                                        jstree
                                    </li>
                                    <li>[JSTREE][GITLAB #314]Add button to open all children on jstree</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Tickets</strong>
                                <ul>
                                    <li>[GITLAB #305] Display resource details on tooltip</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [GANTT][GITLAB #320] Fix status of task changed to &quot;Opened&quot; instead of
                                        not changing it
                                    </li>
                                    <li>[GANTT] Scroll gantt to today in the middle instead of the left of view</li>
                                    <li>[PROJECT_ACTIVITY] The ProjectActivity tag is now well saved</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Tickets</strong>
                                <ul>
                                    <li>
                                        [COMMENT] Fix issue which doesn&#39;t set the comment content in mail and notif
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project Proposal</strong>
                                <ul>
                                    <li>[NOTIF] Fix issue which throw an error when sending PPA</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource</strong>
                                <ul>
                                    <li>[GITLAB #301] Check required fields before submitting</li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-9-5-6dc3e464-">
                            Release notes (<code>2.9.5.6dc3e464</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Networks</strong>
                                <ul>
                                    <li>
                                        Add network (regroup many platforms) and give forms for platform description
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [#373] Multiples reviews. Add possibility to update the status of the review by
                                        a new table. Create a review in the notebook and set the tag associated. Add
                                        deadline (not used).
                                    </li>
                                    <li>Add &#39;User Leader&#39; for the project</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Platform</strong>
                                <ul>
                                    <li>
                                        [CATALOG_PLATFORM] Add catalog of platforms and improve catalog of resources
                                        with platform filter
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Application</strong>
                                <ul>
                                    <li>[MY_DASHBOARD] Add a new dashboard with calendar and graphs</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>[GANTT] Improve filters and design to better visibility</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>
                                        [CALENDAR][[TICKET#263] Update the title of the box when an event is in conflict
                                        with the current one]
                                    </li>
                                    <li>[TICKET#267] Improve input description on ressources</li>
                                    <li>
                                        [TICKET#282] Number type. Improve checking value to avoid error if field not
                                        required
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Application</strong>
                                <ul>
                                    <li>
                                        [CHANGE PLATFORM] Remove empty page (with logo) when connect to platform to go
                                        directly to the dashboard
                                    </li>
                                    <li>
                                        [MENU] Add &quot;Tools&quot; menu . Add message when user is connected but
                                        platform is not selected
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Ticket</strong>
                                <ul>
                                    <li>[ACTIVITY] Improve activity tickets with a timeline</li>
                                    <li>
                                        [TICKET_ACTIVITY][TICKET #284] Ticket Activity. Set the correct user when
                                        activity is created
                                    </li>
                                    <li>
                                        [TICKET_LINKS][TICKET #286] Add controls to no get error on ticket action link
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>[TICKET#277] Add graph links for both sides</li>
                                    <li>Fix issue which doesn&#39;t allow to edit Resources for admin</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Ticket</strong>
                                <ul>
                                    <li>
                                        [MANAGER] Users of the team &quot;General Ticket&quot; can now edit the ticket
                                        after the statut &quot;New&quot;
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-9-4-2cf76307-">
                            Release notes (<code>2.9.4.2cf76307</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Catalog Platform</strong>
                                <ul>
                                    <li>Add catalog of platform sheet</li>
                                    <li>Add possibility to edit platform data</li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-9-3-2a7b96f9-">
                            Release notes (<code>2.9.3.2a7b96f9</code>)
                        </h1>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>LDAP</strong>
                                <ul>
                                    <li>
                                        [TICKET#276] Fix an issue which throw an error when insert a new person on LDAP
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h1 id="release-notes-2-9-2-3e5729d1-">
                            Release notes (<code>2.9.2.3e5729d1</code>)
                        </h1>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>[GANTT] Improve CSS gantt and add id of the tasks in the name</li>
                                    <li>Set Acronym and Title with pre-inserted values from the PPA or Ticket infos</li>
                                    <li>[NOTEBOOK] Change logic to take automatic message in search text</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>[TICKET#274] Fix the export button which throw an error on click.</li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-9-1-b10c658e-">
                            Release notes (<code>2.9.1.b10c658e</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>[TASKS] Add possibility to upload files (multiple) in tasks</li>
                                    <li>
                                        [TASKS][#357] Add dependencies between tasks + duration and days of realisation
                                    </li>
                                    <li>[GANTT][#357] Add dependencies between tasks on Gantt</li>
                                    <li>[GANTT] Add new filter &quot;Steps&quot; to show only one or more steps</li>
                                    <li>
                                        [GANTT] Fix height. Set the header fix even on scroll. Set today&#39;s date in
                                        the middle of the gantt
                                    </li>
                                    <li>[GANTT] Enable edit mode for Gantt modal</li>
                                    <li>
                                        [TICKET#69] Display acronym on menu list + tooltip if too long and increase Team
                                        name according to project name
                                    </li>
                                    <li>
                                        [TASKS] Add information message when date starting date is before
                                        ending/starting date of dependencies
                                    </li>
                                    <li>[TASKS] Improved the task emails updating to get more informations</li>
                                    <li>[PPA][TICKET#72] Add possibility to print the PPA in the modal</li>
                                    <li>[PPA] Add reminder by email for all PPA not taken</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>[TABLE] Keep the state of all tables filters / search</li>
                                    <li>[GITLAB] Change url of the support</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>
                                        [DUPLICATE][TICKET#62] Add a checkbox when duplicate a resource to say if links
                                        should be duplicated or not
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>Update Symfony version 4.4 to 5.4</li>
                                    <li>[SESSION/CONNECTION] Change the way to connect regarding Symfony 5.4</li>
                                    <li>[TICKET#70] Improve URL input type to display the url value as link</li>
                                    <li>[TEAMS] Show project teams everywhere is needed</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>[TICKET#222] Hide type of input in forms (not edit)</li>
                                    <li>
                                        [LINKS][TICKET#67] Add possibility to link multiple values + add graph of links
                                    </li>
                                    <li>[TICKET#268] Add title on tooltip on event in the calendar</li>
                                    <li>
                                        [TICKET#244] Filter eventType is used to filter eventType on modal for
                                        create/edit
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [TASKS] Truncate the project description if it&#39;s is too long into the table
                                    </li>
                                    <li>
                                        Set the Acronym project required and set the team name equal to acronym project
                                        name
                                    </li>
                                    <li>
                                        [TASKS] Add more controls and new message when days of realisation are empty
                                    </li>
                                    <li>[GANTT] Improve CSS to display week-ends on Gantt</li>
                                </ul>
                            </li>
                            <li>
                                <strong>User</strong>
                                <ul>
                                    <li>
                                        On sign-in, increment the unique username even on local (before was only LDAP)
                                        if already used
                                    </li>
                                    <li>
                                        Set the profil with Symfony form and increase telephone size from 16 to 32 chars
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>[NOTEBOOK] Hide button Edit Achieved date if it&#39;s not your post</li>
                                    <li>Fix issue which throw an error when Preload Resource Type is null</li>
                                    <li>
                                        [TICKET#71] Fix issue which throw an error when creating project with name too
                                        long (&gt;64 chars)
                                    </li>
                                    <li>
                                        [STEPS] Fix issue which display a message moreThan or lessThan when edit a step
                                        between starting Dates and ending Dates
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>[MAINTENANCE] Fix bug on maintenance which take maintenance even if deleted</li>
                                    <li>
                                        [MENU] Fix issue which doesn&#39;t display the menu correctly if connected but
                                        not selected platform
                                    </li>
                                    <li>[MENU] Move Help menu at the end of the menu</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Catalog of Resource</strong>
                                <ul>
                                    <li>
                                        Fix issue which loose the platform after display the modal of resource
                                        informations
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>
                                        Fix issue which throw an error when selecting 2 resources and deselect one
                                        (error JS)
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-8-6-83ad95f2-">
                            Release notes (<code>2.8.6.83ad95f2</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>Move the left menu to the top and rearrange it</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource</strong>
                                <ul>
                                    <li>[TICKET#52] Add heritage accesses on resources</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>Improve styling</li>
                                    <li>[MAIL] Send all email asynchronisly</li>
                                    <li>
                                        [MAIL][TICKET#59] Remove [PROD] on all emails when it&#39;s PROD. Keep it when
                                        it&#39;s TEST or DEV
                                    </li>
                                    <li>[TICKET#61] Add .html and .htm format in uploaded files allowed</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Main page</strong>
                                <ul>
                                    <li>[DOCUMENTATION] Add Youtube channel on box in the middle of the root page</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>[TICKET#58] Add tooltip to ticket list and project list</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [TASKS] Fix issue which send email to all project user when updating a task. Add
                                        mail when user is removed to assigned task. Remove template email not used
                                    </li>
                                    <li>
                                        Fix issue which throw an error when description is empty on creation and
                                        it&#39;s not required anymore
                                    </li>
                                    <li>
                                        [GANTT] Fix issue which doesn&#39;t display the name correctly if task was too
                                        large
                                    </li>
                                    <li>[GANTT] Add filters</li>
                                    <li>[TICKET#57] Add acronym in project and in the list</li>
                                    <li>[PROJECT] Fix issue which display validation button on serviceType reading</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource</strong>
                                <ul>
                                    <li>
                                        [RESOURCE][TICKET#52] Improve access and change method load by loadContent when
                                        select or deselect resource in JSTree. Fix issue which doesn&#39;t display the
                                        resources infos when it&#39;s a child (after an update)
                                    </li>
                                    <li>[TICKET#66] Fix image not well formed on dashboard resource</li>
                                    <li>
                                        [RESOURCES] Fix issue which doesn&#39;t display a field in a form list when data
                                        are already set in resourceInfos
                                    </li>
                                    <li>[TEAMS] Hide teams notifs/projects in resources Input Type</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Ticket</strong>
                                <ul>
                                    <li>
                                        [TICKET#64] Fix an issue which throw an error 500 when send a comment in general
                                        ticket
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Catalog of Resources</strong>
                                <ul>
                                    <li>[CATALOG] Fix issue which doesn&#39;t display resource infos correctly</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Teams</strong>
                                <ul>
                                    <li>
                                        [TICKET#68] Fix issue which remove projectTeam when update a user team, so
                                        project disapear
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-8-5-ca6e5b4f-">
                            Release notes (<code>2.8.5.ca6e5b4f</code>)
                        </h1>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>[SESSION] Fix issue on session expired even if not connected</li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-8-4-b74eb93d-">
                            Release notes (<code>2.8.4.b74eb93d</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        Add possibility to change project status with justification and lock the project
                                        if it can&#39;t be edited
                                    </li>
                                    <li>Add conditions &quot;Need all users&quot; in workflow project</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>Add maintenance message and improve maintenance page</li>
                                    <li>Add Changelog</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>[TICKET#47] Add content when creating event</li>
                                    <li>
                                        [TEAMS] Set the Project Teams at the end of the list when selecting a Team
                                        (access)
                                    </li>
                                    <li>[DUPLICATE] Add Resource Source and Resource Destination</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>[TASKS] Add percentage in a Task</li>
                                    <li>[TASKS] Remove taskParent in step/tasks</li>
                                    <li>[TASKS] Improve the tab Tasks in project</li>
                                    <li>
                                        Initial Request is now OPENED and the condition is now &quot;FOLLOW&quot; when
                                        creating a project
                                    </li>
                                    <li>[GANTT] Give access to all user (view mode).</li>
                                    <li>Add button &quot;Edit project&quot; inside the project</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>[ISSUE #41] Hide filters on empty tables</li>
                                    <li>[DOCUMENTATIONS] Add youtube Channel and logo before doc uploaded</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Users</strong>
                                <ul>
                                    <li>Hide project teams in th dashboard and profile</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>[SESSION] Improve session which say session expired even if not connected</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Service Type</strong>
                                <ul>
                                    <li>Fix issue which throw an error when deleting a Task in a ServiceType.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Ticket</strong>
                                <ul>
                                    <li>Fix error when deleting Ticket with resource</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resources</strong>
                                <ul>
                                    <li>
                                        [Issue #31] Create many resources set now the number at the end of the name
                                        instaed of &quot;duplicate&quot;.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Notification</strong>
                                <ul>
                                    <li>[ISSUE #49] Add column user trigger in notifications list</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>
                                        [ISSUE #51] Fix issue which redirect to url http instead of https (so not
                                        allowed message)
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <hr />

                        <h1 id="release-notes-2-8-3-2f6b0908-">
                            Release notes (<code>2.8.3.2f6b0908</code>)
                        </h1>
                        <h2 id="new-features">New features</h2>
                        <ul>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>Add Gantt view</li>
                                    <li>
                                        Add possibility to edit a message and completion date in the tab &quot;Project
                                        Activity&quot;
                                    </li>
                                    <li>
                                        New button is added in &quot;Construction mode&quot; to insert one or many
                                        Service Types after the project is created
                                    </li>
                                    <li>Add possibility to edit a project</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Whole Application</strong>
                                <ul>
                                    <li>Add a button &quot;Go to the top&quot; of the window</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="improvements">Improvements</h2>
                        <ul>
                            <li>
                                <strong>Whole application</strong>
                                <ul>
                                    <li>Improve style button and traductions</li>
                                    <li>
                                        SESSION - Check if session is still alive and increase the expirations time on
                                        API
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>Display users with separated comma in tab Users</li>
                                    <li>
                                        Box &quot;Tree of steps&quot; has changed to get possibility to hide/show
                                        children. Grouped by Service Type
                                    </li>
                                    <li>Task with long name are now displayed</li>
                                    <li>Display now the last 10 project available instead of 5</li>
                                </ul>
                            </li>
                        </ul>
                        <h2 id="bug-fixed">Bug fixed</h2>
                        <ul>
                            <li>
                                <strong>PPA</strong>
                                <ul>
                                    <li>Ticket#50 Impossible to remove PPA demand</li>
                                    <li>The confirmation email now display data with HTML format</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Project</strong>
                                <ul>
                                    <li>Fix issue which display a user even if removed from project</li>
                                    <li>
                                        Fix issue which doesn&#39;t display correctly the automatic activity in filters
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Resource</strong>
                                <ul>
                                    <li>In the Dashboard, reorder box</li>
                                    <li>TICKET#46 The calendar wasn&#39;t displayed all the time</li>
                                    <li>TICKET#45 Problem on links wich has been displayed too many times</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </motion.article>
    )
}
