import {motion} from "framer-motion";
import {FaInfo} from "react-icons/fa";
import useCatalogContext from "../../hooks/useCatalogContext";

export  const ButtonSidebar = ({ isOpen, setIsOpen, catalogType }) => {

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    const { styleCatalog } = useCatalogContext("", catalogType);

    return (

        <motion.button
            className={`border ${styleCatalog} rounded-full text-lg flex items-center justify-center w-8 h-8`}
            onClick={() => handleClick(buttonData.id)}

            whileHover={{scale: 1.025}}
            whileTap={{scale: .945}}

            title={buttonData.title}
            transition={{type: "spring", duration: .3, ease: "linear"}}
        >
            <span className={`text-sm`}>{buttonData.icon}</span>
        </motion.button>

    )
}

const buttonData = {
    id: 1,
    title: "About catalog",
    icon: <FaInfo />
}