import {Link} from "react-router-dom";

const ItemDescriptif = ({ dataInfo, dataTitle, path, email, id, type, title }) => {

    const handleClick = () => {
        if (email) {
            if (!email) return;
            window.location.href = `mailto:${email}`;
        }
    }

    const myStyle = `text-sm text-slate-700 w-1/5 grow ${(path || email) ? "underline cursor-pointer flex gap-2" : "cursor-default"}`;

    return (
        <li className={`flex items-center gap-2 min-h-12 py-4 w-full`}>
            { dataTitle && <p className={`text-sm text-slate-500 font-medium fontfamilly min-w-1/5`}>{dataTitle}</p> }

            {dataInfo ?
                (path ?
                    <Link
                        to={typeof path === "number" ? `/catalog/${id}/${type === "installation" ? "installations" : "services"}/${path}` : path}
                        target={path !== "number" && "_blank"}
                        title={title}
                        className={myStyle}>{dataInfo}
                    </Link>
                    :
                    <p
                        className={myStyle}
                        onClick={handleClick}
                        title={title}
                    >
                        {dataInfo}
                    </p>)
                :
                <span className="text-xs border border-slate-200 bg-slate-50 text-slate-500 px-2">N/A</span>
            }
        </li>
    );
};

export default ItemDescriptif;