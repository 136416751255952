import React from 'react';

const MessageCustom = ({ content }) => {
    return (
        <span className={`border border-slate-200 rounded-sm flex items-center justify-center h-8 px-2 text-xs text-slate-500 absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}>
            {content ? content : "Data not set"}
        </span>
    );
};

export default MessageCustom;
