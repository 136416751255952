import {useParams} from "react-router-dom";
import {Service} from "../../service/pages/Service";
import {Installation} from "../../installation/page/Installation";

export const CatalogDescriptif = () => {

    const { typecatalog } = useParams();

    if (typecatalog === "services") return <Service type="service"/>

    if (typecatalog === "installations") return <Installation type="installations" />
}