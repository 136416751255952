import React, {useState} from 'react';

// Custom Hooks
import {useFetchMaintenances} from "./hooks/useFetchMaintenances";
import {NotificationBarView} from "./NotificationBarView";


export const NotificationBarContainer = ({filters}) => {

    const { maintenancesData } = useFetchMaintenances(filters);

    const [showNotificationBar, setShowNotificationBar] = useState(localStorage.getItem('showNotificationBar') ? JSON.parse(localStorage.getItem('showNotificationBar')) : true);

    const handleToggleBar = () => {
        setShowNotificationBar(!showNotificationBar);
        localStorage.setItem('showNotificationBar', JSON.stringify(!showNotificationBar));
    }

    return (
        maintenancesData.length !== 0 && (
            <NotificationBarView maintenancesData={maintenancesData}
                                 showNotificationBar={showNotificationBar}
                                 handleToggleBar={handleToggleBar}
            />
        )
    )
}