import {Profil} from "../../../../components/users/Profil";
import {SearchModal} from "./SearchModal";

// Custom Hooks
import {useMobile} from "../../../../hooks/useMobile";
import Burger from "../../../../components/header/Burger";
import FastAccess from "../../../auth/components/header/FastAccess";
import Notif from "../../../auth/components/header/Notif";

export const RightMenu = ({ setShow, show, isLoading, userListTeamInfra, userRoleInInfra }) => {

    const isMobile = useMobile()


    return (
        <div className="flex items-center justify-end gap-4 md:min-w-[400px] w-full">
            {/* Choose infra */}
            <SearchModal />

            <ul className=" flex items-center justify-center gap-4 md:relative">
                <li className={`md:relative`}>
                    <FastAccess isMobile={isMobile} />
                </li>
                <li className={`md:relative`}>
                    <Notif />
                </li>
                { !isMobile &&
                    <li className={`md:relative`}>
                        <Profil userListTeamInfra={userListTeamInfra} isLoading={isLoading} userRoleInInfra={userRoleInInfra} />
                    </li>
                }
            </ul>

            { isMobile && <Burger setShow={setShow} show={show}/> }
        </div>
    )
}