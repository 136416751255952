import {NetworkBox} from "../../features/network/components/NetworkBox";
import {LabelStep} from "./LabelStep";
import {useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {CatalogTypeBox} from "../../features/catalog/components/CatalogTypeBox";
import Typography from "@mui/material/Typography";
import {FiArrowLeft, FiArrowRight, FiEye, FiEyeOff, FiInfo, FiPlay} from "react-icons/fi";
import {useMobile} from "../../hooks/useMobile";
import {toast} from "sonner";
import {FaInfo} from "react-icons/fa";

export const BoxStepper = ({ closeDrawer, setCloseDrawer, isOpen, setIsOpen, setCatalogType, catalogType, setNetworkId, networkId, setIsShow}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [networkCode, setNetworkCode] = useState(null);

    const isMobile = useMobile();

    useEffect(() => {
        // Get the activeStep value from localStorage on component mount
        const storedActiveStep = localStorage.getItem("activeStep");
        if (storedActiveStep) {
            setActiveStep(parseInt(storedActiveStep, 10));
        }

        // Drawer
        if (networkId != null) {
            setIsOpen( drawer => drawer = true);
        }

    }, [networkId]);

    const nextStep = () => {
        setActiveStep((currentStep) => {
            if (currentStep > 0) return currentStep;
            return currentStep + 1;
        });
    }
    const backStep = () => {
        setActiveStep((currentStep) => {
            if (currentStep < 0) return currentStep;
            return currentStep - 1;
        });
    }

    const navigate = useNavigate();
    /* When the networkId and catalogType are not null
    * This function it call to redirect to the catalog page with good information
    * */

    const pageCatalogType = ( id, typeCatalog ) => {
        if(!id || !typeCatalog) return toast.warning('network and/or catalog type must be selected')

        switch ( typeCatalog ) {
            case "1":
                typeCatalog = "resources"
                break
            case "2":
                typeCatalog = "installations"
                break
            case "3":
                typeCatalog = "services"
                break
            default:
                console.log('default')
        }

        // Store selected data in localStorage
        localStorage.setItem("selectedData", JSON.stringify({ networkId, catalogType }));

        localStorage.setItem("networkCode", JSON.stringify({ networkCode }));

        return navigate(`/catalog/${id}/${typeCatalog}`)
    }

    const handleMobileOpenDrawer = () => {
        if(!networkId) return toast.warning('Choose a network before viewing information')
        setCloseDrawer(true);
    }

    return(
        <div className="flex flex-col items-center w-full lg:gap-24 gap-10 relative min-h-[60vh] pb-0">
            {/*---------------------------*/}
            { activeStep === 0 &&
                <>
                    <motion.div
                        className="w-full"
                        initial={{opacity: 0, translateY: 25}}
                        animate={{opacity: 1, translateY: 0}}
                        exit={{opacity: 0, translateY: 25}}

                        transition={{type: "spring", duration: .5, ease: "easeIn"}}
                    >
                        <h2 className={`text-3xl md:text-5xl text-slate-750 max-w-[550px]`}
                        >Choose your favorite <span className="text-blue-500 relative catalogTitleSpan servicesSpan">Network</span></h2>
                    </motion.div>

                    {/*Box Network*/}
                    <NetworkBox networkId={networkId} setNetworkId={setNetworkId} setIsShow={setIsShow} />
                </>
            }

            { activeStep === 1 &&
                <>
                    <motion.div className="w-full"
                                initial={{opacity: 0, translateY: 25}}
                                animate={{opacity: 1, translateY: 0}}
                                exit={{opacity: 0, translateY: 25}}

                                transition={{type: "spring", duration: .5, ease: "easeIn"}}
                    >
                        <h2 className={`text-3xl md:text-5xl text-slate-750 max-w-[550px]`}
                        >Choose your type of<span className="text-blue-500 catalogTitleSpan servicesSpan"> Catalog</span> </h2>
                    </motion.div>
                    {/*Box Catalog type*/}
                    <CatalogTypeBox catalogType={catalogType} setCatalogType={setCatalogType}/>
                </>
            }

            {/*--------- Label Step ----------*/}
            <div className="order-first w-full flex items-center justify-center">
                <LabelStep activeStep={activeStep} nextStep={nextStep} backStep={backStep}/>
            </div>
            {/*--------------All button-------------*/}
            <div className={`${isMobile ? " fixed border-t border-slate-200 h-16" : "sticky" } bg-white w-full py-4 z-30 bottom-0 flex items-center justify-center`}>
                <div className={ `wrapper relative flex items-center justify-between`}>

                    <button
                        className={`${isMobile ? "grow flex items-center justify-center w-2/5" : "w-32 border border-slate-500 rounded-full" } h-16 ${(activeStep < 1) ? 'cursor-not-allowed text-gray-400' : 'text-black'} `}
                        onClick={() => backStep()}
                        disabled={ activeStep < 1 && true}
                    >
                        {isMobile && <span className={`mr-2`}><FiArrowLeft /></span>} Back
                    </button>
                    { activeStep < 1 ?
                        <button
                            className={`${isMobile ? "grow flex items-center justify-center w-2/5" : " w-32 border border-slate-500 rounded-full" } h-16`}
                            onClick={() => nextStep()}
                        >
                            Next {isMobile && <span className={`mr-2`}><FiArrowRight /></span>}
                        </button>
                        :
                        <button
                            className={`${isMobile ? "grow flex gap-1 items-center justify-center w-2/5" : " w-32 border border-slate-500 rounded-full" } h-16 ${(!catalogType || !networkId) ? 'cursor-not-allowed text-gray-400' : 'text-slate-700'} `}
                            onClick={() => pageCatalogType(networkId, catalogType)}
                        >
                            Explore {isMobile && <span className={`mr-2`}><FiPlay /></span>}
                        </button>
                    }
                </div>

            </div>

            { isMobile &&
                <button
                    className={`border p-2 border-slate-400 rounded-full h-10 w-10 flex items-center justify-center fixed top-10 right-10 z-10 bg-white`}
                    title="More information about network"
                    onClick={ handleMobileOpenDrawer }
                >
                    <span className={`text-slate-500 text-sm`}><FaInfo /></span>
                </button>
            }

        </div>

    )
}