const BoxListDescriptif = ({ children, border=true }) => {
    return (
        <div className={`mb-4 px-4 py-2 ${border && "border border-slate-200"} w-full rounded-2xl`}>
            <ul className="flex flex-col gap-2 divide-y divide-slate-200">
                { children }
            </ul>
        </div>
    );
};

export default BoxListDescriptif;