import React, { useState, useRef, useEffect } from 'react';
import {motion} from "framer-motion";

const LazyImage = ({ src, alt, list }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the image is visible
        );
        if (imgRef.current) {
            observer.observe(imgRef.current);
        }
        return () => observer.disconnect();
    }, []);

    return (
        <motion.div
            className={`${list ? 'lg:h-80' : 'lg:h-52'} h-[280px]`}
            whileHover={{scale: 1.1}}
            whileTap={{scale: .945}}

            transition={{type: "spring", duration: .5, ease: "easeIn"}}

            ref={imgRef}
        >
            { isVisible ? (
                <motion.figure className={`{${list ? 'lg:h-80' : 'lg:h-52'} h-[280px]`}>
                    <img src={src} alt={alt} className="w-full h-full rounded-lg object-cover"/>
                </motion.figure>

            ) : (

                <div className={`${list ? 'lg:h-80' : 'lg:h-52'} h-[280px] animate-pulse duration-75 bg-slate-200`}></div>
            )}
        </motion.div>
    );
};

export default LazyImage;